import axios from "axios";
import { Component, Context } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, CircularProgress, Grid, InputLabel } from "@mui/material";
import SearchFilter from "../../../Components/Search/SearchFilter";
import { IconType } from "../../../Components/Button/IconButton";
import moment from "moment";
import exceptionRenewService from "../../../Services/ExceptionRenewal";
import DataLoaderRenewal from "../../../Components/Table/DataLoaderRenewal";
import NoRecords from "../../../Components/NoRecords";
import lptService from "../../../Services/LPTService";
import DataLoaderExceptionView from "../../../Components/Table/DataLoaderExceptionView";
import Utils from "../../../Common/Utils";
import { RoleContext } from "../../../Contexts";
import ExceptionRenewalService from "../../../Services/ExceptionRenewal";
import { AuthorizationService } from "../../../Services";

interface Props extends RouteComponentProps<any, any, any> { }

type SortType = {
  key: string;
  direction: string;
}

interface State {
  templateData: any;
  page: number;
  order: string;
  orderBy: string;
  status: string;
  rows: number;
  limit: number;
  gptId?: number;
  search: string;
  searchedData: any
  isloading: boolean;
  searchDebounce: string;
  sortConfig: SortType | null;
  rowTotalCount: number | null;
  tab: number;
}

class Drafted extends Component<Props, State> {
  private searchTimeout: number | null = null;
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: 2,
      sortConfig: null,
      templateData: [],
      page: 1,
      rows: 5,
      limit: 5,
      search: "",
      status: "Drafted",
      searchDebounce: "",
      order: "",
      orderBy: "",
      searchedData: [],
      isloading: false,
      rowTotalCount: 0
    };
  }
  componentDidMount(): void {
    const querySearch = {
      searchtext: this.state.searchDebounce?.trim(),
      order: this.state.order,
      orderBy: this.state.orderBy,
      status: this.state.status,

    }
    this.getLocalProcedure(querySearch);

  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      (
        prevState.searchDebounce !== this.state.searchDebounce
        || prevState.sortConfig !== this.state.sortConfig || prevState.status !== this.state.status )
    ) {
      const querySearch = {
        searchtext: this.state.searchDebounce?.trim(),
        order: this.state.sortConfig?.key,
        orderBy: this.state.sortConfig?.direction,
        status: this.state.status,


      }
      this.getLocalProcedure(querySearch);

    }
    // if (prevState.search !== this.state.search) { this.searchfield() }
  }

  searchfield = () => {
    const { templateData, search } = this.state
    let searcher = templateData.filter((el: any) =>
      el.templateName.startsWith(search.toString().toUpperCase()))

    if (search.length > 0) {
      console.log(templateData, 'executing');
      this.setState({ searchedData: searcher })
    } else {
      console.log(searcher, 'else'); this.setState({ searchedData: templateData })
    }
  };


  handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ search: event.target.value }, () => {
      console.log("search", this.state.search);
      if (this.state.search.length === 0) {
        this.setState({ searchDebounce: this.state.search });
      }
    })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.setState({ searchDebounce: this.state.search });
    }
  };

  getLocalProcedure = async (querySearch: any) => {
    this.setState({ isloading: true });
    try {
      const data = await lptService.getAllExceptionDataByPagination(querySearch);
      const exceptionApprovers = ExceptionRenewalService.getExceptionApproverKeys();

      const formatedData = data?.rows?.map((el: any) => {
        return { ...el, createdDate: moment(el.createdDate).format("L") };
      });
      const renewalFormattedDate = data?.renewalFormData?.map((el: any) => {
        return { ...el, createdDate: moment(el.createdDate).format("L") };
      });
      let combinedData = formatedData?.concat(renewalFormattedDate);

      const isGlobalonlyUser = AuthorizationService.isGlobalonlyUser(this.context);

      if (isGlobalonlyUser) {
        combinedData = combinedData.filter((row: any) => {
          let isValid = false;
          exceptionApprovers.forEach((exceptionApprover) => {
            if (row[exceptionApprover] === this.context.userInfo.preferred_username) {
              if (!isValid) {
                isValid = true;
              }
            }
          });

          return isValid;
        });
      }

      console.log("omit all tabs ==", combinedData);
      this.setState({ templateData: combinedData });
      this.setState({ isloading: false });
      if (data) {
        this.setState({ rowTotalCount: combinedData?.length }, () => {
          console.log('rowTotalCount', combinedData?.length)
        });
      }
    } catch (error) {
      this.setState({ isloading: true });
      if (axios.isAxiosError(error)) {
        this.setState({ isloading: false });
        console.log("error message:", error.message);
        return error.message;
      } else {
        this.setState({ isloading: false });
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }

    }
  };


  requestSort = (key: string) => {
    console.log("key== parent", key);
    const { sortConfig } = this.state;
    let direction = 'ASC';
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'ASC') {
        direction = 'DESC';
      }
    }
    console.log("sortdata = parent", sortConfig);
    this.setState({ sortConfig: { key, direction } }, () => {
      console.log("sortConfig parent=", sortConfig);
      console.log("sortConfig parent=", key);
    });
    // this.sorter()
  }

  onChangePage = (page: number) => {
    console.log("page parent", page)
    if (page == 0) {
      this.setState({ page: 1 });
    } else {
      this.setState({ page });
    }
  };

  onChangeRow = (row: number) => {
    this.setState({ limit: row });
  };

  clickId = (_fieldClicked: string, record: any) => {
    if (record.renewal) {
      window.location.assign(`/exception_renew/${record.lperId}?mode=view`)
    } else {
      window.location.assign(`/raised_exception/${record.gperId}?mode=view`)
    }
  };

  onActionClick = (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        if(record.renewal) {
          window.location.assign(`/exception_renew/${record.lperId}?mode=edit`)  
        } else {

          window.location.assign(`/raised_exception/${record.gperId}?mode=edit`);
        }
        break;
    }
  };

  render() {

    const { templateData, page, limit } = this.state;
    console.log('cont', this.state.rowTotalCount)
    if (this.state.isloading) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    const paginatedData = Utils.paginate(templateData, limit, page);
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <InputLabel>Search By Title</InputLabel>
            <SearchFilter value={this.state.search} onChange={this.handleSearch} onKeyDown={this.onKeyDown}  />
          </Grid>

        </Grid>
        {
          this.state.templateData?.length > 0 ?
            <DataLoaderExceptionView
              tab={this.state.tab}
              sortConfig={this.state.sortConfig}
              requestSort={this.requestSort}
              rowsPerPage={this.state.limit}
              tableData={paginatedData}
              actionArray={["Edit"]}
              onChangePage={this.onChangePage}
              onChangeRow={this.onChangeRow}
              page={this.state.page}
              onClickLink={this.clickId}
              rowTotalCount={this.state.rowTotalCount ? this.state.rowTotalCount : 0}
              onActionClick={this.onActionClick}
              totalRecordsCount={this.state.rowTotalCount ?  this.state.rowTotalCount : 0}
            />
            : <Box style={{ marginTop: 32 }}>
              <NoRecords />
            </Box>

        }

      </>
    );
  }
}

export default withRouter(Drafted);
