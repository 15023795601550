import { UserRole } from "../Common/Enums";

class AuthorizationService {
    isUserAuthorize(allowedRoles: UserRole[], roleContext: { userRoles: UserRole[] }) {
        const { userRoles } = roleContext;
        const roles = userRoles.filter(userRole => allowedRoles.includes(userRole));

        return roles.length > 0;
    }

    isReadonlyUser(roleContext: { userRoles: UserRole[] }) {
        const { userRoles } = roleContext;
        console.log('userRole', userRoles.length)
        const roles = userRoles.filter(userRole => userRole === UserRole.READONLY_USER);
        const global = userRoles.some(userRole => {
            return userRole === UserRole.GLOBAL_USER
        });
        const read = userRoles.some(userRole => {
            return userRole === UserRole.READONLY_USER
        });

        console.log('roles', roles.length)
       if (global && read &&userRoles.length === 2) {
           
            return true;
        }
        return false;
    }
    isAdminUser(roleContext: { userRoles: UserRole[] }) {
        const { userRoles } = roleContext;
        console.log('userRole', userRoles.length)
        const roles = userRoles.filter(userRole => userRole === UserRole.ADMIN_USER);
       

        console.log('roles', roles.length)
       if (roles.length > 0 ) {
           
            return true;
        }
        return false;
    }

    isGlobalonlyUser(roleContext: { userRoles: UserRole[] }) {
        const { userRoles } = roleContext;
        const roles = userRoles.filter(userRole => userRole === UserRole.GLOBAL_USER);
       
        if (roles.length > 0 && userRoles.length === 1) {
            return true;
        }
        return false;
    }

    isECOUser(roleContext: { userRoles: UserRole[] }) {
        const { userRoles } = roleContext;
        const roles = userRoles.filter(userRole => userRole === UserRole.ECO_USER);       

        if (roles.length > 0 ) {           
            return true;
        }
        return false;
    }
}

const authorizationService = new AuthorizationService();
export default authorizationService;