import { Box } from "@mui/material";
import React, { Context } from "react";
import { RouteComponentProps } from "react-router-dom";
import RedButton from "../../Components/Button/RedButton";
import TabPanel from "../../Components/Tab/TabPanel";
import PageHeader from "../../Components/Text/PageHeader";
import TabBar from "./TabBar";
import { TabModel } from "./TabModel";
import axios from "axios";
import ApprovalPending from "./Tabs/ApprovalPending";
import DraftedTab from "./Tabs/DraftedTab";
import RejectedTab from "./Tabs/RejectedTab";
import ActiveTab from "./Tabs/ActiveTab";
import ExpiringTab from "./Tabs/ExpiringTab";
import lptService from "../../Services/LPTService";
import exceptionRenewService from "../../Services/ExceptionRenewal";
import ExceptionTemplate from "./Tabs/ExceptionTemplate";
import { RoleContext } from "../../Contexts";
import { AuthorizationService } from "../../Services";

interface Props extends RouteComponentProps<any, any, any> { }
interface State {
    currentTab: TabModel;
    tabs: TabModel[];
}
class ProcedureException extends React.Component<Props, State> {
    static contextType?: Context<any> = RoleContext;
    context!: React.ContextType<typeof RoleContext>;
    readonly Tabs: TabModel[] = [
        {
            name: "All Exceptions",
            type: "All Exceptions",
            index: 0,
            count: 0,
        },
        {
            name: "Active",
            type: "Active",
            index: 1,
            count: 0,
        },
        {
            name: "Drafted",
            type: "Drafted",
            index: 2,
            count: 0
        },
        {
            name: "Approval Pending",
            type: "Approval Pending",
            index: 3,
            count: 0,
        },
        {
            name: "Expiring",
            type: "Expiring",
            index: 4,
            count: 0,
        },
        {
            name: "Rejected",
            type: "Rejected",
            index: 5,
            count: 0,
        }
    ];
    constructor(props: Props) {
        super(props);
        this.state = {
            currentTab: this.Tabs[0],
            tabs: this.Tabs
        };
    }

    getEcoTabCount = async () => {
        try {
            let ActiveCount = 0;
            let ApprovalPendingCount = 0;
            let ExpiringCount = 0;
            let ExceptionTemplateCount = 0;
            let DraftCount = 0;
            let RejectedCount = 0;

            const querySearchActive = {
                
                searchtext: '',
                order: '',
                orderBy: '',
                status: "Active",
                formtype: ''
            }

            const querySearchExpiring = {
                searchtext: '',
                order: '',
                orderBy: '',
                status: "Expiring",
            }

            const querySearchApprovalPending = {
                
                searchtext: '',
                order: '',
                orderBy: '',
                status: "Approval Pending",
            }

            const querySearchExceptionTemplate = {
                
                searchtext: '',
                order: '',
                orderBy: '',
                status: "",
            }

            const querySearchDraft = {
                
                searchtext: '',
                order: '',
                orderBy: '',
                status: "Drafted",
            }

            const querySearchRejected = {
                
                searchtext: '',
                order: '',
                orderBy: '',
                status: "Rejected",
            }

            const querySearchRenewalDraft = {
                
                searchtext: '',
                order: '',
                orderBy: '',
                status: "Drafted",
            }

            const dataException = await lptService.getAllExceptionDataByPagination(querySearchExceptionTemplate);

            const isGlobalonlyUser = AuthorizationService.isGlobalonlyUser(this.context);
            const exceptionApprovers = exceptionRenewService.getExceptionApproverKeys();

            if (dataException?.rows && dataException?.renewalFormData) {
                let combinedData = dataException?.rows.concat(dataException?.renewalFormData);

                if (isGlobalonlyUser) {
                    combinedData = combinedData.filter((row: any) => {
                        let isValid = false;
                        exceptionApprovers.forEach((exceptionApprover) => {
                            const exceptionApproverData = row[exceptionApprover] ? row[exceptionApprover].toLocaleLowerCase() : '';
                            if (exceptionApproverData === this.context.userInfo.preferred_username) {
                                if (!isValid) {
                                    isValid = true;
                                }
                            }
                        });

                        return isValid;
                    });
                }

                ExceptionTemplateCount = combinedData.length;
            }

            const dataActive = await lptService.getAllExceptionDataByPagination(querySearchActive);
            ;
            if (dataActive?.rows && dataActive?.renewalFormData) {
                let combinedData = dataActive?.rows.concat(dataActive?.renewalFormData);

                if (isGlobalonlyUser) {
                    combinedData = combinedData.filter((row: any) => {
                        let isValid = false;
                        exceptionApprovers.forEach((exceptionApprover) => {
                            const exceptionApproverData = row[exceptionApprover] ? row[exceptionApprover].toLocaleLowerCase() : '';
                            if (exceptionApproverData === this.context.userInfo.preferred_username) {
                                if (!isValid) {
                                    isValid = true;
                                }
                            }
                        });

                        return isValid;
                    });
                }
                ActiveCount = combinedData.length;
            }

            const dataExpiring = await lptService.getAllExceptionDataByPagination(querySearchExpiring);
            if (dataExpiring?.rows && dataExpiring?.renewalFormData) {
                let combinedData = dataExpiring?.rows.concat(dataExpiring?.renewalFormData);

                if (isGlobalonlyUser) {
                    combinedData = combinedData.filter((row: any) => {
                        let isValid = false;
                        exceptionApprovers.forEach((exceptionApprover) => {
                            const exceptionApproverData = row[exceptionApprover] ? row[exceptionApprover].toLocaleLowerCase() : '';
                            if (exceptionApproverData === this.context.userInfo.preferred_username) {
                                if (!isValid) {
                                    isValid = true;
                                }
                            }
                        });

                        return isValid;
                    });
                }

                ExpiringCount = combinedData.length;
            }


            const dataDraft = await lptService.getAllExceptionDataByPagination(querySearchDraft);
            if (dataDraft?.rows && dataDraft?.renewalFormData) {
                let combinedData = dataDraft?.rows.concat(dataDraft?.renewalFormData);

                if (isGlobalonlyUser) {
                    combinedData = combinedData.filter((row: any) => {
                        let isValid = false;
                        exceptionApprovers.forEach((exceptionApprover) => {
                            const exceptionApproverData = row[exceptionApprover] ? row[exceptionApprover].toLocaleLowerCase() : '';
                            if (exceptionApproverData === this.context.userInfo.preferred_username) {
                                if (!isValid) {
                                    isValid = true;
                                }
                            }
                        });

                        return isValid;
                    });
                }

                DraftCount = combinedData.length;
            }

            const dataApprovalPending = await lptService.getAllExceptionDataByPagination(querySearchApprovalPending);
            if (dataApprovalPending?.rows && dataApprovalPending?.renewalFormData) {
                let combinedData = dataApprovalPending?.rows.concat(dataApprovalPending?.renewalFormData);

                if (isGlobalonlyUser) {
                    combinedData = combinedData.filter((row: any) => {
                        let isValid = false;
                        exceptionApprovers.forEach((exceptionApprover) => {
                            const exceptionApproverData = row[exceptionApprover] ? row[exceptionApprover].toLocaleLowerCase() : '';
                            if (exceptionApproverData === this.context.userInfo.preferred_username) {
                                if (!isValid) {
                                    isValid = true;
                                }
                            }
                        });

                        return isValid;
                    });
                }

                ApprovalPendingCount = combinedData.length;
            }


            const dataRejected = await lptService.getAllExceptionDataByPagination(querySearchRejected);
            if (dataRejected?.rows && dataRejected?.renewalFormData) {
                let combinedData = dataRejected?.rows.concat(dataRejected?.renewalFormData);

                if (isGlobalonlyUser) {
                    combinedData = combinedData.filter((row: any) => {
                        let isValid = false;
                        exceptionApprovers.forEach((exceptionApprover) => {
                            const exceptionApproverData = row[exceptionApprover] ? row[exceptionApprover].toLocaleLowerCase() : '';
                            if (exceptionApproverData === this.context.userInfo.preferred_username) {
                                if (!isValid) {
                                    isValid = true;
                                }
                            }
                        });

                        return isValid;
                    });
                }

                RejectedCount = combinedData.length;
            }

            const updatedTabs = [...this.state.tabs];
            // updatedTabs[1].count = AllCount;
            updatedTabs[1].count = ActiveCount;
            updatedTabs[4].count = ExpiringCount;
            updatedTabs[3].count = ApprovalPendingCount;
            updatedTabs[0].count = ExceptionTemplateCount;
            updatedTabs[2].count = DraftCount;
            // updatedTabs[6].count = DraftedRenewal;
            updatedTabs[5].count = RejectedCount;
            this.setState({ tabs: updatedTabs });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message:", error.message);
                return error.message;
            } else {
                console.log("unexpected error during fecthing:", error);
                return "An unexpected error occured";
            }
        }
    };

    componentDidMount(): void {
        let currentTab = sessionStorage.getItem("currentTab");
        if(currentTab)
        {
            this.setState({ currentTab: JSON.parse(currentTab) });
        }
        
        console.log("currentTab=",localStorage.getItem("currentTab"));
        this.getEcoTabCount()
    }

    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab });
        // console.log("selectedTab", selectedTab");
        sessionStorage.setItem("currentTab", JSON.stringify(selectedTab));
    }

    render(): React.ReactNode {
        const isGlobalonlyUser = AuthorizationService.isGlobalonlyUser(this.context);
        let tabs = this.Tabs;
        let currentTab = this.state.currentTab;

        if (isGlobalonlyUser) {
            tabs = this.Tabs.filter(p => p.index === 3);
            currentTab = this.Tabs[3];
        }

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <PageHeader label="Procedure Exceptions" />
                    {isGlobalonlyUser ? null : <Box style={{ display: "flex", justifyContent: "end" }}>
                        <RedButton
                            label="Exception Request Form"
                            onClick={() => {
                                this.props.history.push({
                                    pathname: "/exception", state: {
                                        from: '/procedure_exception'
                                    }
                                });
                            }}
                        />
                    </Box>}
                </Box>
                <TabBar
                    currentTab={currentTab}
                    tabs={tabs}
                    onTabChange={this.handleTabChange}
                />
                {isGlobalonlyUser ? null : <TabPanel value={currentTab.index} index={0}>
                    <ExceptionTemplate />
                </TabPanel>}

                {isGlobalonlyUser ? null : <TabPanel value={currentTab.index} index={1}>
                    <ActiveTab />
                </TabPanel>}
                {isGlobalonlyUser ? null : <TabPanel value={currentTab.index} index={2}>
                    <DraftedTab />
                </TabPanel>}
                <TabPanel value={currentTab.index} index={3}>
                    <ApprovalPending />
                </TabPanel>
                {isGlobalonlyUser ? null : <TabPanel value={currentTab.index} index={4}>
                    <ExpiringTab />
                </TabPanel>}
                {isGlobalonlyUser ? null : <TabPanel value={currentTab.index} index={5}>
                    <RejectedTab />
                </TabPanel>}

            </>
        );
    }

}

export default ProcedureException;
