import { TableContainer, Paper, TableHead, TableBody, Table, TableCell, TableRow, styled, Theme, Typography, Radio } from "@mui/material";
import React from "react";
import FileUploadModel from "./FileUploadModel";
import IconButton from "../../Components/Button/IconButton";

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface State { }
interface Props {
    files: FileUploadModel[];
    selectedFile: FileUploadModel | null;
    onTableRowClick: ((row: FileUploadModel) => void);
    onDeleteRowClick: ((row: FileUploadModel) => void);
}
class ProcedureTable extends React.Component<Props, State> {
    render(): React.ReactNode {
        const { files, selectedFile, onTableRowClick, onDeleteRowClick } = this.props;

        if (files.length < 1) {
            return null;
        }

        return (
            <TableContainer id="table" component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ borderBottom: '2px solid red', whiteSpace: "nowrap" }}>
                            <TableCell></TableCell>
                            <TableCell>
                                <Typography style={{ fontSize: 16, color: '#000000', fontWeight: 500 }}>
                                    Procedure
                                </Typography>
                            </TableCell>
                            {/* <TableCell>
                                <Typography style={{ fontSize: 16, color: '#000000', fontWeight: 500 }}>
                                    Version
                                </Typography>
                            </TableCell> */}
                            <TableCell>
                                <Typography style={{ fontSize: 16, color: '#000000', fontWeight: 500 }}>
                                    Type
                                </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map((row) => (
                            <StyledTableRow style={{ cursor: 'pointer' }}>
                                <TableCell padding="checkbox" onClick={() => onTableRowClick(row)}>
                                    <Radio
                                        style={{ color: '#212121' }}
                                        checked={selectedFile ? selectedFile === row : false}
                                    />
                                </TableCell>
                                <TableCell onClick={() => onTableRowClick(row)}>
                                    <Typography style={{ fontSize: 14, color: '#000000' }}>
                                        {row.name}
                                    </Typography>
                                </TableCell>
                                {/* <TableCell onClick={() => onTableRowClick(row)}>
                                    <Typography style={{ fontSize: 14, color: '#000000' }}>
                                        {row.qDocs?.version}
                                    </Typography>
                                </TableCell> */}
                                <TableCell onClick={() => onTableRowClick(row)}>
                                    <Typography style={{ fontSize: 14, color: '#000000' }}>
                                        {row.fileStatus}
                                    </Typography>
                                </TableCell>
                                <TableCell width={"5%"}>
                                    <IconButton IconType={'Delete'} onClick={() => onDeleteRowClick(row)} />
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default ProcedureTable;