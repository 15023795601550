import { Component, Context } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RenewalForm } from '../../Models/RenewalForm'
import { Box, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, FormLabel, Grid, InputLabel, Typography } from '@mui/material'
import BackLinkButton from '../../Components/Button/BackLinkButton'
import PageHeader from '../../Components/Text/PageHeader'
import WhiteButton from '../../Components/Button/WhiteButton'
import RedButton from '../../Components/Button/RedButton'
import moment from 'moment'
import exceptionRenewService from '../../Services/ExceptionRenewal'
import MyCustomSnackbar from '../../Components/SnackBar/MyCustomSnackbar'
import SelectModel from '../../Components/Select/SelectModel'
import CollapsibleCard from '../../Components/Card/CollapsibleCard'
import gptService from '../../Services/GPTService'
import approvalService from '../../Services/ApprovalService'
import TextAreaInputField from '../../Components/TextInputField/TextAreaInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { AuthProviderService, AuthorizationService } from '../../Services'
import { RoleContext } from '../../Contexts'
import UnauthorizedRoute from '../../Components/Route/UnauthorizedRoute'


interface Props extends RouteComponentProps<any, any, any> { }

interface State {
    renewalForm: RenewalForm;
    throwErr: boolean;
    throwSuccess: boolean;
    errMsg: string;
    successMsg: string;
    lperId?: number;
    procedureNames: any[];
    geographyScope: any[];
    businessScope: any[];
    exceptiongeography: any[];
    exceptionBusinessArea: any[];
    proceduresData: any[];
    load: boolean;
    approverNames: any[];
    approverLoading: boolean;
    m4LegalAttorneySpEcoBlVal: any;
    gmRegionalAffiliateVpVal: any;
    ibuHubGmVal: any;
    associateVpEcoIbuVal: any;
    associateVpLegalIbuVal: any;
    m4EcoBlVal: any;
    m4LegalAttrEcoBlFrapVal: any;
    gmFunctionVpr8Val: any;
    chiefAccOfficerVal: any;
    chiefProcOfficerVal: any;
    localBusinessOwnerVal: any;
    localChiefFinanOwnerVal: any;
    seniorDirEnterTravExpenVal: any;
    seniorVpChiefFinanOffcVal: any;
    localCfoVal: any;
    m5F12BusinessLeaderVal: any;
    approveDialog: boolean;
    rejectDialog: boolean;
    isAuthorize: boolean;
    showButtons: boolean;
};

class RenewalApproval extends Component<Props, State> {
    static contextType?: Context<any> = RoleContext;
    context!: React.ContextType<typeof RoleContext>;

    constructor(props: Props) {
        super(props)
        this.state = {
            renewalForm: {
                exceptionSummary: "",
                expirationDate: null,
                legalRenewalConsultDate: null,
                procedureOwnerRenewalDate: null,
                rationaleForRenewal: "",
                renewal: 0,
                renewalRequestedBy: "",
                renewalSubmittedBy: "",
                exceptionStatus: "",
                title: "",
                businessAreaScope: "",
                ecGeographicm4: "",
                exceptionClassification: "",
                exceptionCompletedBy: "",
                exceptionDescription: "",
                exceptionExpirationDate: null,
                exceptionImpactAfterGrant: "",
                exceptionRationale: "",
                exceptionRequestBy: "",
                exceptionTypeForApproval: "",
                geographyScopeException: "",
                procedure: "",
                requestedTextFromProcedure: "",
                ecAssociateVpOpConsultDate: null,
                ecoBlConsultDate: null,
                ecGeographicm4ConsultDate: null,
                exceptionEffectiveDate: null,
                legalConsultDate: null,
                procedureOwnerConsultDate: null,
            },
            errMsg: "",
            successMsg: "",
            throwErr: false,
            throwSuccess: false,
            exceptionBusinessArea: [],
            exceptiongeography: [],
            proceduresData: [],
            procedureNames: [],
            load: false,
            businessScope: [],
            geographyScope: [],
            approverNames: [],
            approverLoading: false,
            associateVpEcoIbuVal: {},
            associateVpLegalIbuVal: {},
            chiefAccOfficerVal: {},
            chiefProcOfficerVal: {},
            gmFunctionVpr8Val: {},
            gmRegionalAffiliateVpVal: {},
            ibuHubGmVal: {},
            localBusinessOwnerVal: {},
            localCfoVal: {},
            localChiefFinanOwnerVal: {},
            m4EcoBlVal: {},
            m4LegalAttorneySpEcoBlVal: {},
            m4LegalAttrEcoBlFrapVal: {},
            m5F12BusinessLeaderVal: {},
            seniorDirEnterTravExpenVal: {},
            seniorVpChiefFinanOffcVal: {},
            approveDialog: false,
            rejectDialog: false,
            isAuthorize: false,
            showButtons: false,
        }
    }


    async componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>, _snapshot?: any): Promise<void> {
        if (prevState.lperId !== this.state.lperId) {
            await this.getRenewalData();
        }
    };

    getRenewalData = async () => {
        const { lperId } = this.state;
        try {
            let isAuthorize = false;
            const isGlobalonlyUser = AuthorizationService.isGlobalonlyUser(this.context);
            const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);
            const isAdminUser = AuthorizationService.isAdminUser(this.context);
            let showButtons = false
            const data = await exceptionRenewService.getRenewalDataById(lperId!);
            const loginUserEmail = this.context.userInfo.preferred_username as string;
            const exceptionApprovers = exceptionRenewService.getExceptionApproverKeys();

            if (isGlobalonlyUser) {
             
                exceptionApprovers.forEach((exceptionApprover) => {
                    if (!isAuthorize) {
                        if (data) {
                            const keyTyped = exceptionApprover as keyof typeof data;
                            const value = data[keyTyped] as string;

                            if (value && value.toLocaleLowerCase() === loginUserEmail.toLocaleLowerCase()) {
                                isAuthorize = true;
                                showButtons = true;

                                if (exceptionApprover === 'createdByEmail') {
                                    showButtons = false;
                                }
                            }
                        }
                    }
                })
            }  else {
                if (isAdminUser) {
                    isAuthorize = true;
                    showButtons = true;
                  } else {
                   
                    exceptionApprovers.forEach((exceptionApprover) => {
                      if (!isAuthorize) {
                        if (data) {
                          const keyTyped = exceptionApprover as keyof typeof data;
                          const value = data[keyTyped] as string;
            
                          if (value && value.toLocaleLowerCase() === loginUserEmail.toLocaleLowerCase()) {
                            
                            isAuthorize = true;
                            showButtons = true;

                            if (exceptionApprover === 'createdByEmail') {
                                showButtons = false;
                            }
                          }
                        }
                      }
                    });
                  }
            }

            const procedure = data?.procedure.split(",").map((str: any) => { return { text: str, value: str } })
            const geography = data?.geographyScopeException.split(",").map((str: any) => { return { text: str, value: str } })
            const businessScope = data?.businessAreaScope.split(",").map((str: any) => { return { text: str, value: str } })
            this.setState({
                renewalForm: data!, procedureNames: procedure!,showButtons, geographyScope: geography!, businessScope: businessScope!, load: false, isAuthorize
            })
            console.log("Renewal Data", data);

        } catch (error) {
            this.setState({ throwErr: true, errMsg: "Something went wrong while fetching data." })
            console.error("err", error)
        }
    }

    handleInputChange = (event: any) => {
        this.setState({
            renewalForm: {
                ...this.state.renewalForm, [event.target.name]: event.target.value
            }
        })
    }

    render() {
        const { renewalForm, load, errMsg,showButtons, approveDialog, rejectDialog, successMsg, throwErr, throwSuccess, isAuthorize } = this.state;
        const { state } = this.props.location;
        if (load) {
            return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
        }

        if (!isAuthorize) {
            return <UnauthorizedRoute />;
        }

        const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

        return (
            <Box>
                <MyCustomSnackbar
                    message={errMsg}
                    severity="warning"
                    actions={<></>}
                    open={throwErr}
                    onClose={() => {
                        this.setState({ throwErr: false });
                    }}
                />
                <MyCustomSnackbar
                    message={successMsg}
                    severity="success"
                    actions={<></>}
                    open={throwSuccess}
                    onClose={() => {
                        this.setState({ throwSuccess: false });
                    }}
                />
                <Dialog open={approveDialog}>
                    <Grid container>
                        <Grid item>
                            <DialogTitle color={'red'}>Approve Exception Renewal</DialogTitle>
                        </Grid>
                        <Grid item>
                            <FontAwesomeIcon icon={faXmark} size='lg' onClick={() => {
                                this.setState({ approveDialog: false })
                            }} style={{ marginTop: 22, marginLeft: 100, color: 'red', cursor: 'pointer' }} />
                        </Grid>
                    </Grid>
                    <DialogContent dividers>
                        <DialogContentText marginTop={2} color={'black'}>Are you sure you want to approve exception renewal?</DialogContentText>
                        <DialogContent>
                            <Grid container mt={3}>
                                <Grid item mr={2}>
                                    <WhiteButton label='No' onClick={() => { this.setState({ approveDialog: false }) }} />
                                </Grid>
                                <Grid item>
                                    <RedButton label='Yes' onClick={this.handleExceptionRenewalApprove} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </DialogContent>
                </Dialog>
                <Dialog open={rejectDialog}>
                    <Grid container>
                        <Grid item>
                            <DialogTitle color={'red'}>Reject Exception Renewal</DialogTitle>
                        </Grid>
                        <Grid item>
                            <FontAwesomeIcon icon={faXmark} size='lg' onClick={() => {
                                this.setState({ rejectDialog: false })
                            }} style={{ marginTop: 22, marginLeft: 100, color: 'red', cursor: 'pointer' }} />
                        </Grid>
                    </Grid>
                    <DialogContent dividers>
                        <DialogContentText marginTop={2} color={'black'}>Are you sure you want to reject exception renewal?</DialogContentText>
                        <DialogContent>
                            <Grid container mt={3}>
                                <Grid item mr={2}>
                                    <WhiteButton label='No' onClick={() => { this.setState({ rejectDialog: false }) }} />
                                </Grid>
                                <Grid item>
                                    <RedButton label='Yes' onClick={this.handleExceptionRenewalReject} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </DialogContent>
                </Dialog>

                {state && state.success ?

                    <MyCustomSnackbar
                        message={state.msg}
                        severity="success"
                        actions={<></>}
                        open={state.success}
                        onClose={() => {
                            this.setState({ throwSuccess: false });
                        }}
                    /> : <></>}
                <Grid container
                    direction={"row"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1em",
                        justifyContent: "space-between",
                    }}>
                    <Grid item>
                        <Grid container spacing={2} style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Grid item>
                                <BackLinkButton
                                    onClick={() => {

                                        this.props.history.push('/procedure_exception')
                                    }}
                                />
                            </Grid>
                            <Grid style={{ display: "flex", justifyContent: "flex-start" }} item sx={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                maxWidth: '42em'
                            }}>
                                <PageHeader label={`Procedure Exception Renewal Form`} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={() => {
                                    this.props.history.push(`/procedure_exception`)
                                }} />
                            </Grid>
                            {renewalForm.status === "Approval Pending" && showButtons ? <><Grid item>
                                <RedButton label="Approve" onClick={() => {
                                    this.setState({
                                        approveDialog: true
                                    })
                                }} />
                            </Grid><Grid item>
                                    <RedButton label="Reject" onClick={() => {
                                        this.setState({
                                            rejectDialog: true
                                        })
                                    }} />
                                </Grid></> : <></>}
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <>
                    <CollapsibleCard
                        title='Renewal Form'
                        divider
                        initiallyOpen
                        titleStyle={{ fontSize: '26px' }}

                    >

                        <Grid container spacing={2} columns={6}>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                    Title
                                </Typography>
                                <Typography>{renewalForm.title}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                    Exception Summary
                                </Typography>
                                <Typography>{renewalForm.exceptionSummary ? renewalForm.exceptionSummary : `__`}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} columns={6}>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                    Renewal #
                                </Typography>
                                <Typography>{renewalForm.renewal}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                     Renewal Status
                                </Typography>
                                <Typography>{renewalForm.status}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                    Renewal Requested By
                                </Typography>
                                <Typography>{renewalForm.renewalRequestedBy}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} columns={6}>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                    Renewal Submitted By
                                </Typography>
                                <Typography>{renewalForm.renewalSubmittedBy}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                    Expiration Date
                                </Typography>
                                <Typography>{renewalForm.expirationDate ? moment(renewalForm.expirationDate).format("L") : `_`}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>Rationale for Renewal</Typography>
                                <Typography>{renewalForm.rationaleForRenewal}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={8}>
                            <Grid item xs={2.67}>
                                <Typography style={{fontWeight:'bolder'}}>Legal Renewal Consult</Typography>
                                <Typography>{renewalForm.legalRenewalConsultDate ? moment(renewalForm.legalRenewalConsultDate).format("L") : `_`}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{fontWeight:'bolder'}}>
                                    Procedure Owner Renewal Consult
                                </Typography>
                                <Typography>{renewalForm.procedureOwnerRenewalDate ? moment(renewalForm.procedureOwnerRenewalDate).format("L") : `_`}</Typography>
                            </Grid>
                        </Grid>

                    </CollapsibleCard>
                    <CollapsibleCard
                        title='Procedure Details'
                        divider
                        initiallyOpen
                        subTitle="Provide information the approvers needs to evaluate the exception"
                        titleStyle={{ fontSize: '26px' }}
                        subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
                    >
                        <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>Title</Typography>
                                <Typography>{renewalForm.title}</Typography>
                            </Grid>


                            <Grid item xs={2} >
                                <Typography style={{fontWeight:'bolder'}}> Exception Effective Date </Typography>
                                <Typography>{renewalForm.exceptionEffectiveDate ? moment(renewalForm.exceptionEffectiveDate).format("L") : `__`}</Typography>
                            </Grid>
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>Exception Requested By</Typography>
                                <Typography>{renewalForm.exceptionRequestBy}</Typography>
                            </Grid>
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>Exception Form Completed By</Typography>
                                <Typography>{renewalForm.exceptionCompletedBy}</Typography>
                            </Grid>
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>Exception Classification</Typography>
                                <Typography>{renewalForm.exceptionClassification}</Typography>
                            </Grid>

                            <Grid item xs={2}  >

                                <Typography style={{fontWeight:'bolder'}}>Exception Expiration Date </Typography>

                                <Typography>{renewalForm.exceptionExpirationDate ? moment(renewalForm.exceptionExpirationDate).format("L") : `__`}</Typography>

                            </Grid>
                            <Grid item xs={2}  >

                                <Typography style={{fontWeight:'bolder'}}>Procedure(s)</Typography>
                                <Typography>{renewalForm.procedure}</Typography>

                            </Grid>
                            <Grid item xs={2}  >

                                <Typography style={{fontWeight:'bolder'}}>Geography scope for the exception</Typography>
                                <Typography>{renewalForm.geographyScopeException}</Typography>

                            </Grid>
                            <Grid item xs={2}  >

                                <Typography style={{fontWeight:'bolder'}}>Business area scope for the exception</Typography>

                                <Typography>{renewalForm.businessAreaScope}</Typography>

                            </Grid>
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>Describe the exception you are requesting</Typography>
                                <Typography>{renewalForm.exceptionDescription}</Typography>
                            </Grid>
                           
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>What is the impact on the business if the <br></br> exception is granted? If not granted?</Typography>
                                <Typography>{renewalForm.exceptionImpactAfterGrant}</Typography>
                            </Grid>
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>What is the rationale for this exception?</Typography>
                                <Typography>{renewalForm.exceptionRationale}</Typography>
                            </Grid>
                            <Grid item xs={2}  >
                                <Typography style={{fontWeight:'bolder'}}>Insert text from the procedure for which you <br></br>are requesting an exception</Typography>
                                <Typography>{renewalForm.requestedTextFromProcedure}</Typography>
                            </Grid>
                        </Grid>
                    </CollapsibleCard>
                    <CollapsibleCard
                        title='Consultation: Ethics and Compliance'
                        divider

                        subTitle="Summarize the advice you received on this potential exception"
                        titleStyle={{ fontSize: '26px' }}
                        subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
                    >
                        <Box>
                            <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>ECO/BL</Typography>
                                    <Typography>{renewalForm.ecoBl}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>ECO/BL Consult Date</Typography>
                                    {renewalForm.ecoBlConsultDate ?

                                        <Typography>{moment(renewalForm.ecoBlConsultDate).format("L")}</Typography>
                                        : <Typography>____</Typography>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Per ECO/BL: What do applicable international, regional or local industry association <br></br> code(s) say about the subject?</Typography>
                                    <Typography>{renewalForm.ecoBlAssociationCodeSubjectDescription}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+</Typography>
                                    <Typography>{renewalForm.ecGeographicm4}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Consult Date</Typography>
                                    {renewalForm.ecGeographicm4ConsultDate ? <Typography>{moment(renewalForm.ecGeographicm4ConsultDate).format("L")}</Typography> :
                                        <Typography>_____</Typography>}
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Comments</Typography>
                                    <Typography>{renewalForm.ecGeographicm4Comments}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations</Typography>
                                    <Typography>{renewalForm.ecAssociateVpOp}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Consult Date</Typography>
                                    {renewalForm.ecAssociateVpOpConsultDate ? <Typography>{moment(renewalForm.ecAssociateVpOpConsultDate).format("L")}</Typography>
                                        : <Typography>__</Typography>}
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Comments</Typography>
                                    <Typography>{renewalForm.ecAssociateVpOpComments}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CollapsibleCard>
                    <CollapsibleCard title='Consultation: Legal'
                        divider
                        subTitle="Summarize the advice you received on this potential exception. Legal consult applies to
                        IBU exceptions and is optional for other exceptions."
                        titleStyle={{ fontSize: '26px' }}
                        subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>

                        <Box>
                            <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Assigned Legal Counsel</Typography>
                                    <Typography>{renewalForm.assignedLegalCounsel}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Legal Consult Date</Typography>
                                    {renewalForm.legalConsultDate ?
                                        <Typography>{moment(renewalForm.legalConsultDate).format('L')}</Typography> :
                                        <Typography>__</Typography>

                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Per Legal: What are the risks associated with the exception?</Typography>
                                    <Typography>{renewalForm.legalRiskWithException}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Per Legal:How is the exception compliant with applicable law(s),local and foreign laws applied <br></br> to the jurisdiction (e.g., U.S, FCPA,trade sanctions,other countries with extraterritorial reach)?</Typography>
                                    <Typography>{renewalForm.exceptionCompliantWithLaws}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CollapsibleCard>
                    <CollapsibleCard title='Consultation: Procedure Owner'
                        divider

                        subTitle="Summarize the advice you received on this potential exception"
                        titleStyle={{ fontSize: '26px' }}
                        subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
                        <Box>
                            <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Procedure Owner</Typography>
                                    <Typography>{renewalForm.procedureOwner}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Procedure Owner Consult Date</Typography>
                                    {
                                        renewalForm.procedureOwnerConsultDate ?
                                            <Typography>{moment(renewalForm.procedureOwnerConsultDate).format('L')}</Typography>
                                            : <Typography>___</Typography>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Procedure Owner Comments</Typography>
                                    <Typography>{renewalForm.procedureOwnerComments}</Typography>
                                </Grid>

                            </Grid>
                        </Box>
                    </CollapsibleCard>
                    <CollapsibleCard title='Approval: Global Ethics and Compliance Procedure'
                        divider

                        subTitle="Summarize the advice you received on this potential exception"
                        titleStyle={{ fontSize: '26px' }}
                        subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
                        <Box>
                            <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Exception Type for Approval</Typography>
                                    <Typography>{renewalForm.exceptionTypeForApproval}</Typography>
                                </Grid>
                                {renewalForm.exceptionTypeForApproval === "Global Ethics & Compliance Procedure" ? <><Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for E&C Procedure)</Typography>
                                    <Typography>{renewalForm.m4LegalAttorneySpEcoBlName}</Typography>
                                </Grid><Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Regional or Affiliate GM or Business VP at M5/R8+ Level</Typography>
                                        <Typography>{renewalForm.gmRegionalAffiliateVpName}</Typography>
                                    </Grid></> : renewalForm.exceptionTypeForApproval === "Lilly International Affiliate/Hub Local Procedure Requirements" ?
                                    <><Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>IBU Hub GM</Typography>
                                        <Typography>{renewalForm.ibuHubGmName}</Typography>
                                    </Grid><Grid item xs={2}>
                                            <Typography style={{fontWeight:'bolder'}}>Associate VP or Above, Ethics and Compliance, IBU</Typography>
                                            <Typography>{renewalForm.associateVpEcoIbuName}</Typography>
                                        </Grid><Grid item xs={2}>
                                            <Typography style={{fontWeight:'bolder'}}>Associate VP or Above, Legal, IBU</Typography>
                                            <Typography>{renewalForm.associateVpLegalIbuName}</Typography>
                                        </Grid></> : renewalForm.exceptionTypeForApproval === "Global Procedure on Following FRAP" ?
                                        <><Grid item xs={2}>
                                            <Typography style={{fontWeight:'bolder'}}>M4+ ECO/BL</Typography>
                                            <Typography>{renewalForm.m4EcoBlName}</Typography>
                                        </Grid><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for FRAP)</Typography>
                                                <Typography>{renewalForm.m4LegalAttorneySpEcoBlName}</Typography>
                                            </Grid><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>GM or Functional VP at M5/R8 Level</Typography>
                                                <Typography>{renewalForm.gmFunctionVpr8Name}</Typography>
                                            </Grid><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>Chief Accounting Officer </Typography>
                                                <Typography>{renewalForm.chiefAccOfficerName}</Typography>
                                            </Grid><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>Chief Procurement Officer </Typography>
                                                <Typography>{renewalForm.chiefProcOfficerName}</Typography>
                                            </Grid></>
                                        : renewalForm.exceptionTypeForApproval === "Global Travel and Expense Procedure" ?
                                            <><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>Local Business Owner</Typography>
                                                <Typography>{renewalForm.localBusinessOwnerName}</Typography>
                                            </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>Local Chief Financial Officer (CFO)</Typography>
                                                    <Typography>{renewalForm.localChiefFinanOwnerName}</Typography>
                                                </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>Senior Director, Enterprise Travel and Expenser</Typography>
                                                    <Typography>{renewalForm.seniorDirEnterTravExpenName}</Typography>
                                                </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>Senior Vice President and Chief Financial Officer</Typography>
                                                    <Typography>{renewalForm.seniorVpChiefFinanOffcName}</Typography>
                                                </Grid></>
                                            : <><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>Local CFO</Typography>
                                                <Typography>{renewalForm.localCfoName}</Typography>
                                            </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>M5/R12 Business Leader</Typography>
                                                    <Typography>{renewalForm.m5F12BusinessLeaderName}</Typography>
                                                </Grid></>
                                }
                            </Grid>
                        </Box>
                    </CollapsibleCard>
                </>

            </Box>
        )

    };
    async componentDidMount(): Promise<void> {
        this.setState({
            load: true,
            lperId: Number(this.props.match.params.id),
        });
    };

    handleExceptionRenewalApprove = async () => {
        const emails = await AuthProviderService.getAdminEmails()
        const payload = {
            approvalType: "Approved",
            lperId: this.state.lperId!,
            adminEmails: emails.join(',')
        };
        await approvalService.renewalApproval(payload).then((res) => {
            if (res.isSuccess) {
                this.setState({
                    approveDialog: false,
                    successMsg: "The Exception Renewal form have been approved successfully.",
                    throwSuccess: true
                });
                setTimeout(() => {
                    this.props.history.push('/procedure_exception')
                }, 2500)
            } else {
                this.setState({
                    approveDialog: false,
                    throwErr: true,
                    errMsg: "Something went wrong while Approving."
                })
            }
        })
    };

    handleExceptionRenewalReject = async () => {
        const emails = await AuthProviderService.getAdminEmails()
        const payload = {
            approvalType: "Rejected",
            lperId: this.state.lperId!,

            adminEmails: emails.join(',')
        }


        await approvalService.renewalApproval(payload).then((res) => {
            if (res.isSuccess) {
                this.setState({
                    rejectDialog: false,
                    successMsg: "The Exception Renewal form have been rejected.",
                    throwSuccess: true
                });
                setTimeout(() => {
                    this.props.history.push('/procedure_exception')
                }, 2500)
            } else {
                this.setState({
                    rejectDialog: false,
                    throwErr: true,
                    errMsg: "Something went wrong while Rejecting."
                })
            }
        })

    };
}

export default withRouter(RenewalApproval)